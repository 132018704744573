<template>
  <div id="product-comparison" class="comparison-table">
    <div v-for="(column, colIdx) in mappedColumns" :key="colIdx" class="column flex-col">
      <img :src="resize(column.image)" :alt="column.product_name" class="col-img" loading="lazy" />
      <div class="product-name mgn-top-pt5 h4">
        {{ column.product_name }}
      </div>
      <!-- replace image and title above if we want to use dynamic product data -->
      <!-- <ProductCard :product="column.product" :show_add_to_cart="true" :is-dynamic-section="true" render-mode="pdp"/> -->
      <div class="rows flex-col">
        <div v-for="(row, i) in column.row_value" :key="i" class="row">
          <div class="row-value header">
            {{ table_headers[i] }}
          </div>
          <div class="row-value">
            {{ row }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useCloudinary } from '~/composables/cloudinary';
// import { extractComponent } from '~/util/contentstack/csHelpers'
const cloudinary = useCloudinary();

type TableItem = {
  image: any[];
  // productConnection: any;
  product_name: string;
  row_value: string[];
};

const props = defineProps({
  products: {
    type: Array as () => TableItem[],
    required: true,
  },
  // eslint-disable-next-line vue/prop-name-casing
  table_headers: {
    type: Array as () => string[],
    required: true,
  },
});

const mappedColumns = computed<any[]>(() => {
  return props.products.map((product: TableItem) => {
    return {
      image: product?.image?.[0]?.public_id,
      // product: extractComponent(product.productConnection),
      product_name: product.product_name,
      row_value: product.row_value,
    };
  });
});

function resize(url: string) {
  return cloudinary.bynderToCloudinaryResize(url, 320, 320, 1, undefined, undefined);
}
</script>

<style lang="scss">
.comparison-table {
  .yotpo.bottomLine {
    .rating-star {
      font-size: 1rem;
    }
    .text-m {
      font-size: 0.6875rem;
      color: $color-neutral-cool-900 !important;
    }
  }
}
</style>

<style lang="scss" scoped>
.comparison-table {
  display: grid;
  grid-template-columns: 1fr 1fr;
  @include local-mixins.mobile {
    grid-row-gap: 1.5rem;
  }
  @include local-mixins.desktop_and_tablet {
    display: flex;
    justify-content: center;
  }
  .column {
    max-width: #{local-functions.rem-calc(320)};
    margin: 0 8px;
    $spacing: 1rem;
    @include local-mixins.mobile {
      max-width: #{local-functions.rem-calc(400)};
      &:nth-child(even) {
        // left column on mobile
        .row-value.header {
          opacity: 0;
        }
      }
      &:nth-child(2n) {
        // right column on mobile
        .rows {
          .row-value {
            margin-left: -#{$spacing};
            padding-left: $spacing;
          }
        }
      }
    }
    @include local-mixins.desktop_and_tablet {
      &:not(:first-child):not(:last-child) {
        margin: 0 $spacing;
        .rows {
          margin-left: -#{$spacing};
          margin-right: -#{$spacing};
        }
      }
      &:not(:first-child) {
        .row-value {
          padding-left: $spacing;
          &.header {
            opacity: 0;
          }
        }
      }
      &:first-child {
        margin-right: $spacing;
        .rows {
          margin-right: -#{$spacing};
        }
      }
      &:last-child {
        margin-left: $spacing;
        .rows {
          margin-left: -#{$spacing};
        }
      }
    }
    .col-img {
      width: 100%;
    }
    .rows {
      margin-top: 1.5rem;
      gap: 8px;
      @include local-mixins.desktop_and_tablet {
        margin-top: 2rem;
      }
    }
    .row-value {
      margin-bottom: 10px;
      padding-top: 10px;
      &:not(.header) {
        border-top: 1px solid $color-neutral-cool-100;
        font-size: 0.875rem;
        line-height: 1.5;
      }
      &.header {
        font-weight: 500;
        font-size: 1.125rem;
      }
    }
  }
}
</style>
